<template>
  <v-container fill-height :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row justify="center">
      <v-col xs="12" sm="6" md="4">
        <v-card class="elevation-12">
          <v-row>
            <v-card-title primary-title class="cardtext">
              <div>
                <h3 class="headline ml-4">Swagger doc coming soon!</h3>
              </div>
            </v-card-title>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VCard, VCardTitle, VCol, VContainer, VRow } from "vuetify/lib";

export default {
  components: { VCard, VCardTitle, VCol, VContainer, VRow },
};
</script>

<style>
</style>
